import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const LightOrangeBubble = styled(Box)`
  background: #fecaa4;
  opacity: 0.3;
  position: absolute;
  top: 430px;
  left: -14%;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    display: none;
  }
`;

const BlueBubble = styled(Box)`
  background-color: ${({ theme }) => theme.colors.nextonOceanBlue};
  opacity: 0.6;
  position: absolute;
  bottom: 100px;
  right: 8%;
  width: 102px;
  height: 102px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    right: initial;
    left: -75px;
    top: 470px;
  }
`;

const YellowBubble = styled(Box)`
  background-color: ${({ theme }) => theme.colors.nextonYellow};
  position: absolute;
  bottom: 405px;
  right: 0px;
  width: 165px;
  height: 165px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    bottom: 350px;
    right: -20px;
  }
`;

const WhyUsBubbles = () => (
  <Box>
    <LightOrangeBubble />
    <BlueBubble />
    <YellowBubble />
  </Box>
);

export default WhyUsBubbles;
