import React from 'react';
import { Box, Text } from 'rebass';
import CompaniesLogosShorter from '../../components/landings/CompaniesLogosShorter';

const StartupsLogos = () => (
  <Box py='75px' textAlign='center'>
    <Text
      fontSize={['24px', '28px']}
      color='nextonBlack'
      fontWeight='500'
      lineHeight='1.3'
      maxWidth='775px'
      margin='auto'
      mb='40px'
      as='h3'>
      Purpose-driven, VC-backed, high-performance startups taking the lead in decentralized work
    </Text>
    <CompaniesLogosShorter />
  </Box>
);

export default StartupsLogos;
