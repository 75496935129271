import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/layout/Layout';
import MetaData from '../components/MetaData';

import TopEngineersHero from '../sections/Landings/TopEngineersHero';
import WhyUs from '../sections/Landings/WhyUs';
import WhyLATalent from '../sections/Landings/WhyLATalent';
import KickStartYourTeams from '../sections/Landings/KickStartYourTeams';
import StartupsLogos from '../sections/Landings/StartupsLogos';

const HireTopEngineers = () => {
  const { landings } = useStaticQuery(graphql`
    {
      landings: allFile(filter: { relativeDirectory: { eq: "images/landings" } }) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED, formats: [PNG])
          }
          name
        }
      }
    }
  `);
  const landingsImages = {};
  landings.nodes.forEach(img => {
    landingsImages[img.name] = { ...img.childImageSharp.gatsbyImageData };
  });

  return (
    <>
      <MetaData
        title='Hire Top Engineers'
        description='We specialize in headhunting top-notch, Latin American-based technical talent'
      />
      <Layout selected='ad-landing-page'>
        <TopEngineersHero title='Hire the top senior software engineers in LATAM' landingsImages={landingsImages} />
        <WhyUs />
        <WhyLATalent landingsImages={landingsImages} />
        <KickStartYourTeams landingsImages={landingsImages} />
        <StartupsLogos />
      </Layout>
    </>
  );
};

export default HireTopEngineers;
