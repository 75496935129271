import React, { useEffect } from 'react';
import { Box, Flex, Text } from 'rebass';
import styled from 'styled-components';
import { BgImage } from 'gbimage-bridge';
import { useHSFormsScriptLoaded } from '../../helpers/hooks';

import WhiteRoundCard2 from '../../components/client/WhiteRoundCard2';

const StyledBgImage = styled(BgImage)`
  background-size: auto;
  background-position: left bottom;
  margin: 0px -20px;
`;

const TopEngineersHero = ({ title, landingsImages }) => {
  const HSFormsScriptLoaded = useHSFormsScriptLoaded();

  useEffect(() => {
    if (HSFormsScriptLoaded) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '14573977',
        formId: 'd698fd9f-471c-42d7-a277-4c08ccc063d1',
        target: '#top-engineers-form',
      });
    }
  }, [HSFormsScriptLoaded]);

  return (
    <StyledBgImage image={landingsImages['yellow-line']} alt='Landing Image'>
      <Box mx='20px'>
        <Flex
          flexWrap='wrap'
          pt={['0px', '40px']}
          pb={['250px', '200px']}
          style={{ position: 'relative', zIndex: '0' }}>
          <Box width={[1, 1, 1 / 2]} px={['0px', '20px']}>
            <Text color='nextonBlue' fontSize='28px' fontWeight='500' id='for-companies-title'>
              For companies
            </Text>
            <Text
              color='nextonBlack'
              fontSize={['40px', '60px']}
              fontWeight='500'
              letterSpacing='-1px'
              py={['30px', '45px']}
              as='h1'>
              {title}
            </Text>
            <Text
              color='#7798A8'
              fontSize={['22px', '28px']}
              lineHeight='1.4'
              pb='50px'
              style={{
                textShadow: '0px 0px 4px rgba(255, 255, 255, 1),0px 0px 5px rgba(255, 255, 255, 1)',
              }}
              as='h2'
              fontWeight='400'>
              At Nexton, we specialize in headhunting top-notch, Latin American-based technical talent. Through our
              refined engineer-led system, we significantly improve recruiting funnel metrics, saving you time and money
              in the process.
            </Text>
          </Box>
          <Box width={[1, 1, 1 / 2]} px={['0px', '20px']} mb='-100px'>
            <WhiteRoundCard2 padding='40px 35px' maxWidth='560px'>
              <Box>
                <Text
                  color='nextonBlue'
                  fontSize={['22px', '28px']}
                  fontWeight={500}
                  textAlign='center'
                  mt='10px'
                  mb='40px'>
                  Boost your capabilities with world-class technical teams
                </Text>
                <div id='top-engineers-form' style={{ minHeight: '428px' }} />
              </Box>
            </WhiteRoundCard2>
          </Box>
        </Flex>
      </Box>
    </StyledBgImage>
  );
};

export default TopEngineersHero;
