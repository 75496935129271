import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

const LightBlueBubble = styled(Box)`
  background: #ade4f0;
  position: absolute;
  bottom: 60px;
  left: -13%;
  width: 290px;
  height: 290px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    display: none;
  }
`;

const LightOrangeBubble = styled(Box)`
  background: #fecaa4;
  position: absolute;
  top: 470px;
  left: 95%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    display: none;
  }
`;

const BlueBubble = styled(Box)`
  background-color: ${({ theme }) => theme.colors.nextonLightBlue2};
  opacity: 0.6;
  position: absolute;
  bottom: 200px;
  right: 14%;
  width: 138px;
  height: 138px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    display: none;
  }
`;

const LightGrayBubble = styled(Box)`
  background-color: #ebedee;
  position: absolute;
  top: 160px;
  left: 160px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    top: 190px;
    left: -20px;
  }
`;

const YellowBubble = styled(Box)`
  background-color: #fce296;
  position: absolute;
  bottom: 0px;
  right: -50px;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  z-index: -1;

  @media (max-width: 959px) {
    bottom: 350px;
    right: -20px;
  }
`;

const KickStartBubbles = () => (
  <Box>
    <LightBlueBubble />
    <LightGrayBubble />
    <BlueBubble />
    <YellowBubble />
    <LightOrangeBubble />
  </Box>
);

export default KickStartBubbles;
