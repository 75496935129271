import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';

import WhiteRoundCard2 from '../../components/client/WhiteRoundCard2';

const WhyLATalent = ({ landingsImages }) => (
  <Box py={['35px', '75px']}>
    <Box pt='75px' pb={['45px', '75px']} bg='nextonLightGrey' style={{ borderRadius: '24px' }}>
      <Text
        fontSize={['40px', '60px']}
        fontWeight='500'
        letterSpacing='-1px'
        color='nextonBlack'
        textAlign='center'
        mb='50px'
        as='h2'>
        Why Latin American talent?
      </Text>
      <Flex flexWrap='wrap' justifyContent='center' mx='12px'>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={['0px', '10px']} py='15px'>
          <WhiteRoundCard2 padding='40px 35px' maxWidth='560px'>
            <Box textAlign='center' minHeight='255px'>
              <GatsbyImage image={landingsImages.clock} alt='clock' />
              <Text fontSize={['24px', '28px']} color='nextonBlack' fontWeight='500' my='20px'>
                Your timezone
              </Text>
              <Text color='rgba(8, 30, 42, 0.6)' fontSize={['18px', '20px']} letterSpacing='-0.5px'>
                Sharing the same longitude across the Americas, your team can have up to 8 hours of overlap, maximizing
                your ability to collaborate in real-time.
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={['0px', '10px']} py='15px'>
          <WhiteRoundCard2 padding='40px 35px' maxWidth='560px'>
            <Box textAlign='center' minHeight='255px'>
              <GatsbyImage image={landingsImages.rocket} alt='rocket' />
              <Text fontSize={['24px', '28px']} color='nextonBlack' fontWeight='500' my='20px'>
                Incredibly talented
              </Text>
              <Text color='rgba(8, 30, 42, 0.6)' fontSize={['18px', '20px']} letterSpacing='-0.5px'>
                Latin America provides highly educated, innovative and ambitious engineering talent.
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={['0px', '10px']} py='15px'>
          <WhiteRoundCard2 padding='40px 35px' maxWidth='560px'>
            <Box textAlign='center' minHeight='255px'>
              <GatsbyImage image={landingsImages.laptop} alt='laptop' />
              <Text fontSize={['24px', '28px']} color='nextonBlack' fontWeight='500' my='20px'>
                Remote-friendly
              </Text>
              <Text color='rgba(8, 30, 42, 0.6)' fontSize={['18px', '20px']} letterSpacing='-0.5px'>
                LATAM talent has many years of remote work experience which translates into a seamless transition to
                your team.
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={['0px', '10px']} py='15px'>
          <WhiteRoundCard2 padding='40px 35px' maxWidth='560px'>
            <Box textAlign='center' minHeight='255px'>
              <GatsbyImage image={landingsImages.money} alt='money' />
              <Text fontSize={['24px', '28px']} color='nextonBlack' fontWeight='500' my='20px'>
                Low cost
              </Text>
              <Text color='rgba(8, 30, 42, 0.6)' fontSize={['18px', '20px']} letterSpacing='-0.5px'>
                With matching skill sets, engineers from Latin America remain around 45% cheaper than in the US.
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={['0px', '10px']} py='15px'>
          <WhiteRoundCard2 padding='40px 35px' maxWidth='560px'>
            <Box textAlign='center' minHeight='255px'>
              <GatsbyImage image={landingsImages.chart} alt='chart' />
              <Text fontSize={['24px', '28px']} color='nextonBlack' fontWeight='500' my='20px'>
                Mission-oriented
              </Text>
              <Text color='rgba(8, 30, 42, 0.6)' fontSize={['18px', '20px']} letterSpacing='-0.5px'>
                LATAM engineers are passionate about getting involved in purposeful companies and making a change.
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 3]} px={['0px', '10px']} py='15px'>
          <WhiteRoundCard2 padding='40px 35px' maxWidth='560px'>
            <Box textAlign='center' minHeight='255px'>
              <GatsbyImage image={landingsImages.voice} alt='voice' />
              <Text fontSize={['24px', '28px']} color='nextonBlack' fontWeight='500' my='20px'>
                Great cultural fit
              </Text>
              <Text color='rgba(8, 30, 42, 0.6)' fontSize={['18px', '20px']} letterSpacing='-0.5px'>
                Motivated, competitive, diverse people with excellent English communication skills.
              </Text>
            </Box>
          </WhiteRoundCard2>
        </Box>
      </Flex>
    </Box>
  </Box>
);

export default WhyLATalent;
