import React from 'react';
import { Box, Flex, Text, Button } from 'rebass';
import { GatsbyImage } from 'gatsby-plugin-image';
import KickStartBubbles from '../../components/landings/KickStartBubbles';

import { scrollToEl } from '../../helpers/functions';

const KickStartYourTeams = ({ landingsImages }) => (
  <Box py='75px' textAlign='center' style={{ position: 'relative' }}>
    <Text
      fontSize={['40px', '60px']}
      fontWeight='500'
      letterSpacing='-1px'
      color='nextonBlack'
      maxWidth='650px'
      margin='auto'
      mb={['60px', '70px']}
      as='h2'>
      Kick-start your teams with Nexton
    </Text>
    <Flex flexWrap='wrap' mb={['60px', '80px']}>
      <Box width={[1, 1, 11 / 20]} px={['0px', '20px']}>
        <Box mb='25px'>
          <GatsbyImage
            image={landingsImages.agile}
            alt='agile'
            style={{
              boxShadow: '0px 41px 60px 11px rgba(161, 161, 161, 0.16)',
              borderRadius: '13px',
            }}
          />
        </Box>
      </Box>
      <Box width={[1, 1, 9 / 20]} px={['0px', '20px']} textAlign={['center', 'center', 'left']}>
        <Text fontSize={['36px', '42px']} color='nextonBlue' fontWeight='500' mb='15px'>
          We are agile
        </Text>
        <Text fontSize={['18px', '20px']} color='#7798A8' lineHeight='1.3'>
          Nexton's process was carefully crafted by engineers. Our headhunting team is sophisticated, quick, and
          efficient. We can complete a recruiting process from start to finish in 3 to 6 weeks. Yes, only two sprints.
        </Text>
      </Box>
    </Flex>
    <Flex flexWrap='wrap' mb={['60px', '80px']}>
      <Box
        width={[1, 1, 9 / 20]}
        px={['0px', '20px']}
        textAlign={['center', 'center', 'right']}
        order={['2', '2', '1']}>
        <Text fontSize={['36px', '42px']} color='nextonBlue' fontWeight='500' mb='15px'>
          We are efficient
        </Text>
        <Text fontSize={['18px', '20px']} color='#7798A8' lineHeight='1.3'>
          Remove all the clutter from the recruiting process. Our track record is 4:1 submit to hire ratio. You will
          only interview 3 to 8 thoroughly selected candidates, investing just the right amount of your time to find the
          ideal team member.
        </Text>
      </Box>
      <Box width={[1, 1, 11 / 20]} px={['0px', '20px']} order={['1', '1', '2']}>
        <Box mb='25px'>
          <GatsbyImage
            image={landingsImages.efficient}
            alt='efficient'
            style={{
              boxShadow: '0px 41px 60px 11px rgba(161, 161, 161, 0.16)',
              borderRadius: '13px',
            }}
          />
        </Box>
      </Box>
    </Flex>
    <Button variant='blueXXL' onClick={() => scrollToEl('#top-engineers-form')}>
      Start upgrading my teams
    </Button>
    <KickStartBubbles />
  </Box>
);

export default KickStartYourTeams;
