import React from 'react';
import { Flex, Box, Button, Text } from 'rebass';
import NumbersRising from '../../components/common/NumbersRising';
import WhyUsBubbles from '../../components/landings/WhyUsBubbles';

import { scrollToEl } from '../../helpers/functions';

const WhyUs = () => (
  <Box pt={['75px', '0px']} pb='75px' style={{ position: 'relative' }}>
    <Text
      fontSize={['40px', '60px']}
      fontWeight='500'
      letterSpacing='-1px'
      color='nextonBlack'
      textAlign='center'
      mb={['20px', '40px']}
      as='h2'>
      Why us?
    </Text>
    <Flex justifyContent='center' alignItems='flex-start' flexWrap='wrap'>
      <NumbersRising title='Why us?' />
      <Button variant='blueXXL' onClick={() => scrollToEl('#top-engineers-form')}>
        Build my teams now
      </Button>
    </Flex>
    <WhyUsBubbles />
  </Box>
);

export default WhyUs;
